<template>
      <div class="row">
        <div class="col-12">
          <div class="multi_content">
            <div class="multi_content--text">
              <span>{{multi.title}}</span>
              <span>{{multi.text}}</span>
            </div>
            <div class="multi_content--action">
              <Button
                size=""
                color="primary"
                :outline="false"
                :block="false"
                :disabled="false"
                :href="null"
                :to="null"
                @clicked="reset"
              >
                {{multi.button}}
              </Button>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Button } from '@cendyn/cendyn-frontend-kit';

export default {
  name: 'UserSecurity',
  components: {
    Button
  },
  data() {
    return {
      multi: {
        title: 'Reset Password',
        text: 'You can reset your password via email. Click the button and shortly you will receive an email with instructions to proceed.',
        button: 'Reset Password'
      }
    };
  },
  computed: {
    ...mapGetters('currentUser', [
      'getUserInfo'
    ])
  },
  methods: {
    reset() {
      console.log('Submit Reset Password Request for: ' + this.getUserInfo.email);
      this.$store.dispatch('okta/passwordReset', { user: this.getUserInfo.email}).then(response => {
        console.log(response);
        this.$store.dispatch('alerts/alertAction', { alert: { icon: 'check-circle', color: 'success', dismissible: false, message: 'We have sent you an email with instructions. You will be logged out now.'}});
        let self = this;
        setTimeout(function(){ self.$router.push({ path: '/logout' }); }, 3000);
      }, error => {
        this.$store.dispatch('alerts/alertAction', { alert: { icon: 'check-circle', color: 'danger', dismissible: false, message: error}});
        console.log(error);
      });
    }
  }
};
</script>

<style lang="scss">
.multi_content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 36px;
    > div:not(:first-child) {
      padding-left: 24px;
    }
    &--text {
      flex: 1;
      span {
        display: block;
        &:not(:first-child) {
          color: #6E84A3
        }
      }
    }
  }
</style>
